var exports = {};
exports = {
  A: {
    A: {
      "1": "F A B",
      "2": "6B",
      "8": "J D E"
    },
    B: {
      "1": "C K L G M N O",
      "129": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB 8B 9B",
      "8": "7B qB",
      "129": "SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB",
      "4": "I",
      "129": "NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC"
    },
    E: {
      "1": "r J D E F B C K L G DC EC FC GC xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "8": "I CC wB",
      "129": "A"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 B C M N O s t u v w x y z AB BB OC nB 4B PC oB",
      "2": "F G LC",
      "8": "MC NC",
      "129": "CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b"
    },
    G: {
      "1": "E wB QC 5B RC SC TC UC VC WC",
      "129": "XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B"
    },
    H: {
      "2": "kC"
    },
    I: {
      "1": "qB I lC mC nC oC 5B pC qC",
      "129": "H"
    },
    J: {
      "1": "D A"
    },
    K: {
      "1": "B C nB 4B oB",
      "8": "A",
      "129": "d"
    },
    L: {
      "129": "H"
    },
    M: {
      "129": "c"
    },
    N: {
      "1": "A B"
    },
    O: {
      "129": "rC"
    },
    P: {
      "1": "I",
      "129": "sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "129": "yB"
    },
    R: {
      "129": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 2,
  C: "Geolocation"
};
export default exports;