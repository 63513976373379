var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F 6B",
      "8": "A B"
    },
    B: {
      "1": "P",
      "2": "Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "8": "C K L G M N O"
    },
    C: {
      "2": "0 1 2 7B qB I r J D E F A B C K L G M N O s t u v w x y z 8B 9B",
      "8": "3 4 TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "72": "5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB"
    },
    D: {
      "1": "9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P",
      "2": "0 1 2 I r J D E F A B C K L G M N O s t u v w x y z Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "66": "3 4 5 6 7",
      "72": "8"
    },
    E: {
      "2": "I r CC wB DC",
      "8": "J D E F A B C K L G EC FC GC xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB",
      "2": "F B C G M cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b LC MC NC OC nB 4B PC oB",
      "66": "N O s t u",
      "72": "v"
    },
    G: {
      "2": "wB QC 5B RC SC",
      "8": "E TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B"
    },
    H: {
      "2": "kC"
    },
    I: {
      "2": "qB I H lC mC nC oC 5B pC qC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "2": "A B C d nB 4B oB"
    },
    L: {
      "2": "H"
    },
    M: {
      "8": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "I sC tC uC vC wC xB xC yC",
      "2": "zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "2": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 5,
  C: "HTML Imports"
};
export default exports;