var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 6B"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "2": "C",
      "260": "K L G M N O"
    },
    C: {
      "1": "NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "0 1 7B qB I r J D E F A B C K L G M N O s t u v w x y z 8B 9B",
      "516": "2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB"
    },
    D: {
      "1": "QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "I",
      "16": "r J D E F A B C K L",
      "260": "PB",
      "772": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB"
    },
    E: {
      "1": "B C K L G xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I CC wB",
      "16": "r",
      "772": "J D E F A DC EC FC GC"
    },
    F: {
      "1": "DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "16": "F LC",
      "260": "B C CB MC NC OC nB 4B PC oB",
      "772": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB"
    },
    G: {
      "1": "YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "wB QC 5B",
      "772": "E RC SC TC UC VC WC XC"
    },
    H: {
      "132": "kC"
    },
    I: {
      "1": "H",
      "2": "qB lC mC nC",
      "260": "I oC 5B pC qC"
    },
    J: {
      "2": "D",
      "260": "A"
    },
    K: {
      "1": "d",
      "260": "A B C nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C",
      "260": "I"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "516": "6C"
    }
  },
  B: 5,
  C: ":in-range and :out-of-range CSS pseudo-classes"
};
export default exports;