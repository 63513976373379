var exports = {};
exports = {
  A: {
    A: {
      "2": "6B",
      "8": "J D E",
      "772": "F A B"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "513": "C K L G M N O"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 qB I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB 8B 9B",
      "4": "7B"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC"
    },
    E: {
      "1": "I r J D E F A B C K L G wB DC EC FC GC xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "4": "CC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 F B C G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b LC MC NC OC nB 4B PC oB"
    },
    G: {
      "1": "E wB QC 5B RC SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B"
    },
    H: {
      "1": "kC"
    },
    I: {
      "1": "H pC qC",
      "2": "lC mC nC",
      "132": "qB I oC 5B"
    },
    J: {
      "1": "D A"
    },
    K: {
      "1": "A B C d nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "257": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 4,
  C: "SVG (basic support)"
};
export default exports;