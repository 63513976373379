var exports = {};
exports = {
  A: {
    A: {
      "1": "A B",
      "2": "J D E F 6B"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "2": "C K L G M N O"
    },
    C: {
      "1": "AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB 8B 9B",
      "132": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "I",
      "16": "r J D E u v w x y",
      "132": "F A B C K L G M N O s t"
    },
    E: {
      "1": "C K L G nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I r CC wB DC",
      "132": "J D E F A B EC FC GC xB"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "F B C LC MC NC OC nB 4B PC oB"
    },
    G: {
      "2": "SC TC",
      "132": "E UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "514": "wB QC 5B RC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "2": "lC mC nC",
      "260": "qB I oC 5B",
      "514": "H pC qC"
    },
    J: {
      "132": "A",
      "260": "D"
    },
    K: {
      "2": "A B C nB 4B oB",
      "514": "d"
    },
    L: {
      "260": "H"
    },
    M: {
      "2": "c"
    },
    N: {
      "514": "A",
      "1028": "B"
    },
    O: {
      "2": "rC"
    },
    P: {
      "260": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "260": "yB"
    },
    R: {
      "260": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 1,
  C: "accept attribute for file input"
};
export default exports;