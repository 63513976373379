var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 6B"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "2": "C K",
      "322": "L",
      "8196": "G M N O"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 7B qB I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB 8B 9B",
      "4162": "SB TB UB VB rB WB sB XB YB ZB aB",
      "16452": "bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB"
    },
    D: {
      "1": "mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB",
      "194": "QB RB SB TB UB VB",
      "1090": "rB WB",
      "8196": "sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB"
    },
    E: {
      "1": "K L G oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I r J D E F CC wB DC EC FC GC",
      "514": "A B xB",
      "8196": "C nB"
    },
    F: {
      "1": "bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "0 1 2 3 4 5 6 7 8 9 F B C G M N O s t u v w x y z AB BB CB LC MC NC OC nB 4B PC oB",
      "194": "DB EB FB GB HB IB JB KB",
      "8196": "LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB"
    },
    G: {
      "1": "cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "E wB QC 5B RC SC TC UC VC WC",
      "514": "XC YC ZC",
      "8196": "aC bC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "2": "qB I H lC mC nC oC 5B pC qC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "d",
      "2": "A B C nB 4B oB"
    },
    L: {
      "2049": "H"
    },
    M: {
      "2": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "rC"
    },
    P: {
      "1": "yC zC 0C 1C pB 2C 3C 4C",
      "2": "I",
      "8196": "sC tC uC vC wC xB xC"
    },
    Q: {
      "8196": "yB"
    },
    R: {
      "2": "5C"
    },
    S: {
      "2": "6C"
    }
  },
  B: 2,
  C: "Payment Request API"
};
export default exports;