var exports = {};
exports = {
  A: {
    A: {
      "2": "F A B 6B",
      "8": "J D E"
    },
    B: {
      "2": "C K L G M N O",
      "8": "P Q R S T U V W X Y Z a b e f g h",
      "584": "i j k l m n o p q c H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "129": "7B qB 8B 9B"
    },
    D: {
      "1": "x",
      "8": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h",
      "584": "i j k l m n o p q c H uB vB AC BC"
    },
    E: {
      "1": "A B C K L G xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "260": "I r J D E F CC wB DC EC FC GC"
    },
    F: {
      "2": "F",
      "8": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB",
      "584": "S T U V W X Y Z a b",
      "2052": "B C LC MC NC OC nB 4B PC oB"
    },
    G: {
      "1": "E RC SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "8": "wB QC 5B"
    },
    H: {
      "8": "kC"
    },
    I: {
      "8": "qB I H lC mC nC oC 5B pC qC"
    },
    J: {
      "1": "A",
      "8": "D"
    },
    K: {
      "8": "A B C d nB 4B oB"
    },
    L: {
      "8": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "8": "rC"
    },
    P: {
      "8": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "8": "yB"
    },
    R: {
      "8": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 2,
  C: "MathML"
};
export default exports;