import _aac from "./features/aac";
import _abortcontroller from "./features/abortcontroller";
import _ac3Ec from "./features/ac3-ec3";
import _accelerometer from "./features/accelerometer";
import _addeventlistener from "./features/addeventlistener";
import _alternateStylesheet from "./features/alternate-stylesheet";
import _ambientLight from "./features/ambient-light";
import _apng from "./features/apng";
import _arrayFindIndex from "./features/array-find-index";
import _arrayFind from "./features/array-find";
import _arrayFlat from "./features/array-flat";
import _arrayIncludes from "./features/array-includes";
import _arrowFunctions from "./features/arrow-functions";
import _asmjs from "./features/asmjs";
import _asyncClipboard from "./features/async-clipboard";
import _asyncFunctions from "./features/async-functions";
import _atobBtoa from "./features/atob-btoa";
import _audioApi from "./features/audio-api";
import _audio from "./features/audio";
import _audiotracks from "./features/audiotracks";
import _autofocus from "./features/autofocus";
import _auxclick from "./features/auxclick";
import _av from "./features/av1";
import _avif from "./features/avif";
import _backgroundAttachment from "./features/background-attachment";
import _backgroundClipText from "./features/background-clip-text";
import _backgroundImgOpts from "./features/background-img-opts";
import _backgroundPositionXY from "./features/background-position-x-y";
import _backgroundRepeatRoundSpace from "./features/background-repeat-round-space";
import _backgroundSync from "./features/background-sync";
import _batteryStatus from "./features/battery-status";
import _beacon from "./features/beacon";
import _beforeafterprint from "./features/beforeafterprint";
import _bigint from "./features/bigint";
import _blobbuilder from "./features/blobbuilder";
import _bloburls from "./features/bloburls";
import _borderImage from "./features/border-image";
import _borderRadius from "./features/border-radius";
import _broadcastchannel from "./features/broadcastchannel";
import _brotli from "./features/brotli";
import _calc from "./features/calc";
import _canvasBlending from "./features/canvas-blending";
import _canvasText from "./features/canvas-text";
import _canvas from "./features/canvas";
import _chUnit from "./features/ch-unit";
import _chacha20Poly from "./features/chacha20-poly1305";
import _channelMessaging from "./features/channel-messaging";
import _childnodeRemove from "./features/childnode-remove";
import _classlist from "./features/classlist";
import _clientHintsDprWidthViewport from "./features/client-hints-dpr-width-viewport";
import _clipboard from "./features/clipboard";
import _colrV from "./features/colr-v1";
import _colr from "./features/colr";
import _comparedocumentposition from "./features/comparedocumentposition";
import _consoleBasic from "./features/console-basic";
import _consoleTime from "./features/console-time";
import _const from "./features/const";
import _constraintValidation from "./features/constraint-validation";
import _contenteditable from "./features/contenteditable";
import _contentsecuritypolicy from "./features/contentsecuritypolicy";
import _contentsecuritypolicy2 from "./features/contentsecuritypolicy2";
import _cookieStoreApi from "./features/cookie-store-api";
import _cors from "./features/cors";
import _createimagebitmap from "./features/createimagebitmap";
import _credentialManagement from "./features/credential-management";
import _cryptography from "./features/cryptography";
import _cssAll from "./features/css-all";
import _cssAnimation from "./features/css-animation";
import _cssAnyLink from "./features/css-any-link";
import _cssAppearance from "./features/css-appearance";
import _cssAtCounterStyle from "./features/css-at-counter-style";
import _cssAutofill from "./features/css-autofill";
import _cssBackdropFilter from "./features/css-backdrop-filter";
import _cssBackgroundOffsets from "./features/css-background-offsets";
import _cssBackgroundblendmode from "./features/css-backgroundblendmode";
import _cssBoxdecorationbreak from "./features/css-boxdecorationbreak";
import _cssBoxshadow from "./features/css-boxshadow";
import _cssCanvas from "./features/css-canvas";
import _cssCaretColor from "./features/css-caret-color";
import _cssCascadeLayers from "./features/css-cascade-layers";
import _cssCaseInsensitive from "./features/css-case-insensitive";
import _cssClipPath from "./features/css-clip-path";
import _cssColorAdjust from "./features/css-color-adjust";
import _cssColorFunction from "./features/css-color-function";
import _cssConicGradients from "./features/css-conic-gradients";
import _cssContainerQueries from "./features/css-container-queries";
import _cssContainerQueryUnits from "./features/css-container-query-units";
import _cssContainment from "./features/css-containment";
import _cssContentVisibility from "./features/css-content-visibility";
import _cssCounters from "./features/css-counters";
import _cssCrispEdges from "./features/css-crisp-edges";
import _cssCrossFade from "./features/css-cross-fade";
import _cssDefaultPseudo from "./features/css-default-pseudo";
import _cssDescendantGtgt from "./features/css-descendant-gtgt";
import _cssDeviceadaptation from "./features/css-deviceadaptation";
import _cssDirPseudo from "./features/css-dir-pseudo";
import _cssDisplayContents from "./features/css-display-contents";
import _cssElementFunction from "./features/css-element-function";
import _cssEnvFunction from "./features/css-env-function";
import _cssExclusions from "./features/css-exclusions";
import _cssFeaturequeries from "./features/css-featurequeries";
import _cssFileSelectorButton from "./features/css-file-selector-button";
import _cssFilterFunction from "./features/css-filter-function";
import _cssFilters from "./features/css-filters";
import _cssFirstLetter from "./features/css-first-letter";
import _cssFirstLine from "./features/css-first-line";
import _cssFixed from "./features/css-fixed";
import _cssFocusVisible from "./features/css-focus-visible";
import _cssFocusWithin from "./features/css-focus-within";
import _cssFontPalette from "./features/css-font-palette";
import _cssFontRenderingControls from "./features/css-font-rendering-controls";
import _cssFontStretch from "./features/css-font-stretch";
import _cssGencontent from "./features/css-gencontent";
import _cssGradients from "./features/css-gradients";
import _cssGridAnimation from "./features/css-grid-animation";
import _cssGrid from "./features/css-grid";
import _cssHangingPunctuation from "./features/css-hanging-punctuation";
import _cssHas from "./features/css-has";
import _cssHyphens from "./features/css-hyphens";
import _cssImageOrientation from "./features/css-image-orientation";
import _cssImageSet from "./features/css-image-set";
import _cssInOutOfRange from "./features/css-in-out-of-range";
import _cssIndeterminatePseudo from "./features/css-indeterminate-pseudo";
import _cssInitialLetter from "./features/css-initial-letter";
import _cssInitialValue from "./features/css-initial-value";
import _cssLchLab from "./features/css-lch-lab";
import _cssLetterSpacing from "./features/css-letter-spacing";
import _cssLineClamp from "./features/css-line-clamp";
import _cssLogicalProps from "./features/css-logical-props";
import _cssMarkerPseudo from "./features/css-marker-pseudo";
import _cssMasks from "./features/css-masks";
import _cssMatchesPseudo from "./features/css-matches-pseudo";
import _cssMathFunctions from "./features/css-math-functions";
import _cssMediaInteraction from "./features/css-media-interaction";
import _cssMediaRangeSyntax from "./features/css-media-range-syntax";
import _cssMediaResolution from "./features/css-media-resolution";
import _cssMediaScripting from "./features/css-media-scripting";
import _cssMediaqueries from "./features/css-mediaqueries";
import _cssMixblendmode from "./features/css-mixblendmode";
import _cssMotionPaths from "./features/css-motion-paths";
import _cssNamespaces from "./features/css-namespaces";
import _cssNesting from "./features/css-nesting";
import _cssNotSelList from "./features/css-not-sel-list";
import _cssNthChildOf from "./features/css-nth-child-of";
import _cssOpacity from "./features/css-opacity";
import _cssOptionalPseudo from "./features/css-optional-pseudo";
import _cssOverflowAnchor from "./features/css-overflow-anchor";
import _cssOverflowOverlay from "./features/css-overflow-overlay";
import _cssOverflow from "./features/css-overflow";
import _cssOverscrollBehavior from "./features/css-overscroll-behavior";
import _cssPageBreak from "./features/css-page-break";
import _cssPagedMedia from "./features/css-paged-media";
import _cssPaintApi from "./features/css-paint-api";
import _cssPlaceholderShown from "./features/css-placeholder-shown";
import _cssPlaceholder from "./features/css-placeholder";
import _cssPrintColorAdjust from "./features/css-print-color-adjust";
import _cssReadOnlyWrite from "./features/css-read-only-write";
import _cssRebeccapurple from "./features/css-rebeccapurple";
import _cssReflections from "./features/css-reflections";
import _cssRegions from "./features/css-regions";
import _cssRepeatingGradients from "./features/css-repeating-gradients";
import _cssResize from "./features/css-resize";
import _cssRevertValue from "./features/css-revert-value";
import _cssRrggbbaa from "./features/css-rrggbbaa";
import _cssScrollBehavior from "./features/css-scroll-behavior";
import _cssScrollTimeline from "./features/css-scroll-timeline";
import _cssScrollbar from "./features/css-scrollbar";
import _cssSel from "./features/css-sel2";
import _cssSel2 from "./features/css-sel3";
import _cssSelection from "./features/css-selection";
import _cssShapes from "./features/css-shapes";
import _cssSnappoints from "./features/css-snappoints";
import _cssSticky from "./features/css-sticky";
import _cssSubgrid from "./features/css-subgrid";
import _cssSupportsApi from "./features/css-supports-api";
import _cssTable from "./features/css-table";
import _cssTextAlignLast from "./features/css-text-align-last";
import _cssTextIndent from "./features/css-text-indent";
import _cssTextJustify from "./features/css-text-justify";
import _cssTextOrientation from "./features/css-text-orientation";
import _cssTextSpacing from "./features/css-text-spacing";
import _cssTextshadow from "./features/css-textshadow";
import _cssTouchAction from "./features/css-touch-action";
import _cssTransitions from "./features/css-transitions";
import _cssUnicodeBidi from "./features/css-unicode-bidi";
import _cssUnsetValue from "./features/css-unset-value";
import _cssVariables from "./features/css-variables";
import _cssWhenElse from "./features/css-when-else";
import _cssWidowsOrphans from "./features/css-widows-orphans";
import _cssWidthStretch from "./features/css-width-stretch";
import _cssWritingMode from "./features/css-writing-mode";
import _cssZoom from "./features/css-zoom";
import _css3Attr from "./features/css3-attr";
import _css3Boxsizing from "./features/css3-boxsizing";
import _css3Colors from "./features/css3-colors";
import _css3CursorsGrab from "./features/css3-cursors-grab";
import _css3CursorsNewer from "./features/css3-cursors-newer";
import _css3Cursors from "./features/css3-cursors";
import _css3Tabsize from "./features/css3-tabsize";
import _currentcolor from "./features/currentcolor";
import _customElements from "./features/custom-elements";
import _customElementsv from "./features/custom-elementsv1";
import _customevent from "./features/customevent";
import _datalist from "./features/datalist";
import _dataset from "./features/dataset";
import _datauri from "./features/datauri";
import _dateTolocaledatestring from "./features/date-tolocaledatestring";
import _declarativeShadowDom from "./features/declarative-shadow-dom";
import _decorators from "./features/decorators";
import _details from "./features/details";
import _deviceorientation from "./features/deviceorientation";
import _devicepixelratio from "./features/devicepixelratio";
import _dialog from "./features/dialog";
import _dispatchevent from "./features/dispatchevent";
import _dnssec from "./features/dnssec";
import _doNotTrack from "./features/do-not-track";
import _documentCurrentscript from "./features/document-currentscript";
import _documentEvaluateXpath from "./features/document-evaluate-xpath";
import _documentExeccommand from "./features/document-execcommand";
import _documentPolicy from "./features/document-policy";
import _documentScrollingelement from "./features/document-scrollingelement";
import _documenthead from "./features/documenthead";
import _domManipConvenience from "./features/dom-manip-convenience";
import _domRange from "./features/dom-range";
import _domcontentloaded from "./features/domcontentloaded";
import _dommatrix from "./features/dommatrix";
import _download from "./features/download";
import _dragndrop from "./features/dragndrop";
import _elementClosest from "./features/element-closest";
import _elementFromPoint from "./features/element-from-point";
import _elementScrollMethods from "./features/element-scroll-methods";
import _eme from "./features/eme";
import _eot from "./features/eot";
import _es from "./features/es5";
import _es6Class from "./features/es6-class";
import _es6Generators from "./features/es6-generators";
import _es6ModuleDynamicImport from "./features/es6-module-dynamic-import";
import _es6Module from "./features/es6-module";
import _es6Number from "./features/es6-number";
import _es6StringIncludes from "./features/es6-string-includes";
import _es2 from "./features/es6";
import _eventsource from "./features/eventsource";
import _extendedSystemFonts from "./features/extended-system-fonts";
import _featurePolicy from "./features/feature-policy";
import _fetch from "./features/fetch";
import _fieldsetDisabled from "./features/fieldset-disabled";
import _fileapi from "./features/fileapi";
import _filereader from "./features/filereader";
import _filereadersync from "./features/filereadersync";
import _filesystem from "./features/filesystem";
import _flac from "./features/flac";
import _flexboxGap from "./features/flexbox-gap";
import _flexbox from "./features/flexbox";
import _flowRoot from "./features/flow-root";
import _focusinFocusoutEvents from "./features/focusin-focusout-events";
import _fontFamilySystemUi from "./features/font-family-system-ui";
import _fontFeature from "./features/font-feature";
import _fontKerning from "./features/font-kerning";
import _fontLoading from "./features/font-loading";
import _fontSizeAdjust from "./features/font-size-adjust";
import _fontSmooth from "./features/font-smooth";
import _fontUnicodeRange from "./features/font-unicode-range";
import _fontVariantAlternates from "./features/font-variant-alternates";
import _fontVariantNumeric from "./features/font-variant-numeric";
import _fontface from "./features/fontface";
import _formAttribute from "./features/form-attribute";
import _formSubmitAttributes from "./features/form-submit-attributes";
import _formValidation from "./features/form-validation";
import _forms from "./features/forms";
import _fullscreen from "./features/fullscreen";
import _gamepad from "./features/gamepad";
import _geolocation from "./features/geolocation";
import _getboundingclientrect from "./features/getboundingclientrect";
import _getcomputedstyle from "./features/getcomputedstyle";
import _getelementsbyclassname from "./features/getelementsbyclassname";
import _getrandomvalues from "./features/getrandomvalues";
import _gyroscope from "./features/gyroscope";
import _hardwareconcurrency from "./features/hardwareconcurrency";
import _hashchange from "./features/hashchange";
import _heif from "./features/heif";
import _hevc from "./features/hevc";
import _hidden from "./features/hidden";
import _highResolutionTime from "./features/high-resolution-time";
import _history from "./features/history";
import _htmlMediaCapture from "./features/html-media-capture";
import _html5semantic from "./features/html5semantic";
import _httpLiveStreaming from "./features/http-live-streaming";
import _http from "./features/http2";
import _http2 from "./features/http3";
import _iframeSandbox from "./features/iframe-sandbox";
import _iframeSeamless from "./features/iframe-seamless";
import _iframeSrcdoc from "./features/iframe-srcdoc";
import _imagecapture from "./features/imagecapture";
import _ime from "./features/ime";
import _imgNaturalwidthNaturalheight from "./features/img-naturalwidth-naturalheight";
import _importMaps from "./features/import-maps";
import _imports from "./features/imports";
import _indeterminateCheckbox from "./features/indeterminate-checkbox";
import _indexeddb from "./features/indexeddb";
import _indexeddb2 from "./features/indexeddb2";
import _inlineBlock from "./features/inline-block";
import _innertext from "./features/innertext";
import _inputAutocompleteOnoff from "./features/input-autocomplete-onoff";
import _inputColor from "./features/input-color";
import _inputDatetime from "./features/input-datetime";
import _inputEmailTelUrl from "./features/input-email-tel-url";
import _inputEvent from "./features/input-event";
import _inputFileAccept from "./features/input-file-accept";
import _inputFileDirectory from "./features/input-file-directory";
import _inputFileMultiple from "./features/input-file-multiple";
import _inputInputmode from "./features/input-inputmode";
import _inputMinlength from "./features/input-minlength";
import _inputNumber from "./features/input-number";
import _inputPattern from "./features/input-pattern";
import _inputPlaceholder from "./features/input-placeholder";
import _inputRange from "./features/input-range";
import _inputSearch from "./features/input-search";
import _inputSelection from "./features/input-selection";
import _insertAdjacent from "./features/insert-adjacent";
import _insertadjacenthtml from "./features/insertadjacenthtml";
import _internationalization from "./features/internationalization";
import _intersectionobserverV from "./features/intersectionobserver-v2";
import _intersectionobserver from "./features/intersectionobserver";
import _intlPluralrules from "./features/intl-pluralrules";
import _intrinsicWidth from "./features/intrinsic-width";
import _jpeg from "./features/jpeg2000";
import _jpegxl from "./features/jpegxl";
import _jpegxr from "./features/jpegxr";
import _jsRegexpLookbehind from "./features/js-regexp-lookbehind";
import _json from "./features/json";
import _justifyContentSpaceEvenly from "./features/justify-content-space-evenly";
import _kerningPairsLigatures from "./features/kerning-pairs-ligatures";
import _keyboardeventCharcode from "./features/keyboardevent-charcode";
import _keyboardeventCode from "./features/keyboardevent-code";
import _keyboardeventGetmodifierstate from "./features/keyboardevent-getmodifierstate";
import _keyboardeventKey from "./features/keyboardevent-key";
import _keyboardeventLocation from "./features/keyboardevent-location";
import _keyboardeventWhich from "./features/keyboardevent-which";
import _lazyload from "./features/lazyload";
import _let from "./features/let";
import _linkIconPng from "./features/link-icon-png";
import _linkIconSvg from "./features/link-icon-svg";
import _linkRelDnsPrefetch from "./features/link-rel-dns-prefetch";
import _linkRelModulepreload from "./features/link-rel-modulepreload";
import _linkRelPreconnect from "./features/link-rel-preconnect";
import _linkRelPrefetch from "./features/link-rel-prefetch";
import _linkRelPreload from "./features/link-rel-preload";
import _linkRelPrerender from "./features/link-rel-prerender";
import _loadingLazyAttr from "./features/loading-lazy-attr";
import _localecompare from "./features/localecompare";
import _magnetometer from "./features/magnetometer";
import _matchesselector from "./features/matchesselector";
import _matchmedia from "./features/matchmedia";
import _mathml from "./features/mathml";
import _maxlength from "./features/maxlength";
import _mdnCssUnicodeBidiIsolateOverride from "./features/mdn-css-unicode-bidi-isolate-override";
import _mdnCssUnicodeBidiIsolate from "./features/mdn-css-unicode-bidi-isolate";
import _mdnCssUnicodeBidiPlaintext from "./features/mdn-css-unicode-bidi-plaintext";
import _mdnTextDecorationColor from "./features/mdn-text-decoration-color";
import _mdnTextDecorationLine from "./features/mdn-text-decoration-line";
import _mdnTextDecorationShorthand from "./features/mdn-text-decoration-shorthand";
import _mdnTextDecorationStyle from "./features/mdn-text-decoration-style";
import _mediaFragments from "./features/media-fragments";
import _mediacaptureFromelement from "./features/mediacapture-fromelement";
import _mediarecorder from "./features/mediarecorder";
import _mediasource from "./features/mediasource";
import _menu from "./features/menu";
import _metaThemeColor from "./features/meta-theme-color";
import _meter from "./features/meter";
import _midi from "./features/midi";
import _minmaxwh from "./features/minmaxwh";
import _mp from "./features/mp3";
import _mpegDash from "./features/mpeg-dash";
import _mpeg from "./features/mpeg4";
import _multibackgrounds from "./features/multibackgrounds";
import _multicolumn from "./features/multicolumn";
import _mutationEvents from "./features/mutation-events";
import _mutationobserver from "./features/mutationobserver";
import _namevalueStorage from "./features/namevalue-storage";
import _nativeFilesystemApi from "./features/native-filesystem-api";
import _navTiming from "./features/nav-timing";
import _netinfo from "./features/netinfo";
import _notifications from "./features/notifications";
import _objectEntries from "./features/object-entries";
import _objectFit from "./features/object-fit";
import _objectObserve from "./features/object-observe";
import _objectValues from "./features/object-values";
import _objectrtc from "./features/objectrtc";
import _offlineApps from "./features/offline-apps";
import _offscreencanvas from "./features/offscreencanvas";
import _oggVorbis from "./features/ogg-vorbis";
import _ogv from "./features/ogv";
import _olReversed from "./features/ol-reversed";
import _onceEventListener from "./features/once-event-listener";
import _onlineStatus from "./features/online-status";
import _opus from "./features/opus";
import _orientationSensor from "./features/orientation-sensor";
import _outline from "./features/outline";
import _padStartEnd from "./features/pad-start-end";
import _pageTransitionEvents from "./features/page-transition-events";
import _pagevisibility from "./features/pagevisibility";
import _passiveEventListener from "./features/passive-event-listener";
import _passwordrules from "./features/passwordrules";
import _path2d from "./features/path2d";
import _paymentRequest from "./features/payment-request";
import _pdfViewer from "./features/pdf-viewer";
import _permissionsApi from "./features/permissions-api";
import _permissionsPolicy from "./features/permissions-policy";
import _pictureInPicture from "./features/picture-in-picture";
import _picture from "./features/picture";
import _ping from "./features/ping";
import _pngAlpha from "./features/png-alpha";
import _pointerEvents from "./features/pointer-events";
import _pointer from "./features/pointer";
import _pointerlock from "./features/pointerlock";
import _portals from "./features/portals";
import _prefersColorScheme from "./features/prefers-color-scheme";
import _prefersReducedMotion from "./features/prefers-reduced-motion";
import _progress from "./features/progress";
import _promiseFinally from "./features/promise-finally";
import _promises from "./features/promises";
import _proximity from "./features/proximity";
import _proxy from "./features/proxy";
import _publickeypinning from "./features/publickeypinning";
import _pushApi from "./features/push-api";
import _queryselector from "./features/queryselector";
import _readonlyAttr from "./features/readonly-attr";
import _referrerPolicy from "./features/referrer-policy";
import _registerprotocolhandler from "./features/registerprotocolhandler";
import _relNoopener from "./features/rel-noopener";
import _relNoreferrer from "./features/rel-noreferrer";
import _rellist from "./features/rellist";
import _rem from "./features/rem";
import _requestanimationframe from "./features/requestanimationframe";
import _requestidlecallback from "./features/requestidlecallback";
import _resizeobserver from "./features/resizeobserver";
import _resourceTiming from "./features/resource-timing";
import _restParameters from "./features/rest-parameters";
import _rtcpeerconnection from "./features/rtcpeerconnection";
import _ruby from "./features/ruby";
import _runIn from "./features/run-in";
import _sameSiteCookieAttribute from "./features/same-site-cookie-attribute";
import _screenOrientation from "./features/screen-orientation";
import _scriptAsync from "./features/script-async";
import _scriptDefer from "./features/script-defer";
import _scrollintoview from "./features/scrollintoview";
import _scrollintoviewifneeded from "./features/scrollintoviewifneeded";
import _sdch from "./features/sdch";
import _selectionApi from "./features/selection-api";
import _serverTiming from "./features/server-timing";
import _serviceworkers from "./features/serviceworkers";
import _setimmediate from "./features/setimmediate";
import _shadowdom from "./features/shadowdom";
import _shadowdomv from "./features/shadowdomv1";
import _sharedarraybuffer from "./features/sharedarraybuffer";
import _sharedworkers from "./features/sharedworkers";
import _sni from "./features/sni";
import _spdy from "./features/spdy";
import _speechRecognition from "./features/speech-recognition";
import _speechSynthesis from "./features/speech-synthesis";
import _spellcheckAttribute from "./features/spellcheck-attribute";
import _sqlStorage from "./features/sql-storage";
import _srcset from "./features/srcset";
import _stream from "./features/stream";
import _streams from "./features/streams";
import _stricttransportsecurity from "./features/stricttransportsecurity";
import _styleScoped from "./features/style-scoped";
import _subresourceBundling from "./features/subresource-bundling";
import _subresourceIntegrity from "./features/subresource-integrity";
import _svgCss from "./features/svg-css";
import _svgFilters from "./features/svg-filters";
import _svgFonts from "./features/svg-fonts";
import _svgFragment from "./features/svg-fragment";
import _svgHtml from "./features/svg-html";
import _svgHtml2 from "./features/svg-html5";
import _svgImg from "./features/svg-img";
import _svgSmil from "./features/svg-smil";
import _svg from "./features/svg";
import _sxg from "./features/sxg";
import _tabindexAttr from "./features/tabindex-attr";
import _templateLiterals from "./features/template-literals";
import _template from "./features/template";
import _temporal from "./features/temporal";
import _testfeat from "./features/testfeat";
import _textDecoration from "./features/text-decoration";
import _textEmphasis from "./features/text-emphasis";
import _textOverflow from "./features/text-overflow";
import _textSizeAdjust from "./features/text-size-adjust";
import _textStroke from "./features/text-stroke";
import _textcontent from "./features/textcontent";
import _textencoder from "./features/textencoder";
import _tls from "./features/tls1-1";
import _tls2 from "./features/tls1-2";
import _tls3 from "./features/tls1-3";
import _touch from "./features/touch";
import _transforms2d from "./features/transforms2d";
import _transforms3d from "./features/transforms3d";
import _trustedTypes from "./features/trusted-types";
import _ttf from "./features/ttf";
import _typedarrays from "./features/typedarrays";
import _u2f from "./features/u2f";
import _unhandledrejection from "./features/unhandledrejection";
import _upgradeinsecurerequests from "./features/upgradeinsecurerequests";
import _urlScrollToTextFragment from "./features/url-scroll-to-text-fragment";
import _url from "./features/url";
import _urlsearchparams from "./features/urlsearchparams";
import _useStrict from "./features/use-strict";
import _userSelectNone from "./features/user-select-none";
import _userTiming from "./features/user-timing";
import _variableFonts from "./features/variable-fonts";
import _vectorEffect from "./features/vector-effect";
import _vibration from "./features/vibration";
import _video from "./features/video";
import _videotracks from "./features/videotracks";
import _viewportUnitVariants from "./features/viewport-unit-variants";
import _viewportUnits from "./features/viewport-units";
import _waiAria from "./features/wai-aria";
import _wakeLock from "./features/wake-lock";
import _wasm from "./features/wasm";
import _wav from "./features/wav";
import _wbrElement from "./features/wbr-element";
import _webAnimation from "./features/web-animation";
import _webAppManifest from "./features/web-app-manifest";
import _webBluetooth from "./features/web-bluetooth";
import _webSerial from "./features/web-serial";
import _webShare from "./features/web-share";
import _webauthn from "./features/webauthn";
import _webcodecs from "./features/webcodecs";
import _webgl from "./features/webgl";
import _webgl2 from "./features/webgl2";
import _webgpu from "./features/webgpu";
import _webhid from "./features/webhid";
import _webkitUserDrag from "./features/webkit-user-drag";
import _webm from "./features/webm";
import _webnfc from "./features/webnfc";
import _webp from "./features/webp";
import _websockets from "./features/websockets";
import _webtransport from "./features/webtransport";
import _webusb from "./features/webusb";
import _webvr from "./features/webvr";
import _webvtt from "./features/webvtt";
import _webworkers from "./features/webworkers";
import _webxr from "./features/webxr";
import _willChange from "./features/will-change";
import _woff from "./features/woff";
import _woff2 from "./features/woff2";
import _wordBreak from "./features/word-break";
import _wordwrap from "./features/wordwrap";
import _xDocMessaging from "./features/x-doc-messaging";
import _xFrameOptions from "./features/x-frame-options";
import _xhr from "./features/xhr2";
import _xhtml from "./features/xhtml";
import _xhtmlsmil from "./features/xhtmlsmil";
import _xmlSerializer from "./features/xml-serializer";
var exports = {};
exports = {
  "aac": _aac,
  "abortcontroller": _abortcontroller,
  "ac3-ec3": _ac3Ec,
  "accelerometer": _accelerometer,
  "addeventlistener": _addeventlistener,
  "alternate-stylesheet": _alternateStylesheet,
  "ambient-light": _ambientLight,
  "apng": _apng,
  "array-find-index": _arrayFindIndex,
  "array-find": _arrayFind,
  "array-flat": _arrayFlat,
  "array-includes": _arrayIncludes,
  "arrow-functions": _arrowFunctions,
  "asmjs": _asmjs,
  "async-clipboard": _asyncClipboard,
  "async-functions": _asyncFunctions,
  "atob-btoa": _atobBtoa,
  "audio-api": _audioApi,
  "audio": _audio,
  "audiotracks": _audiotracks,
  "autofocus": _autofocus,
  "auxclick": _auxclick,
  "av1": _av,
  "avif": _avif,
  "background-attachment": _backgroundAttachment,
  "background-clip-text": _backgroundClipText,
  "background-img-opts": _backgroundImgOpts,
  "background-position-x-y": _backgroundPositionXY,
  "background-repeat-round-space": _backgroundRepeatRoundSpace,
  "background-sync": _backgroundSync,
  "battery-status": _batteryStatus,
  "beacon": _beacon,
  "beforeafterprint": _beforeafterprint,
  "bigint": _bigint,
  "blobbuilder": _blobbuilder,
  "bloburls": _bloburls,
  "border-image": _borderImage,
  "border-radius": _borderRadius,
  "broadcastchannel": _broadcastchannel,
  "brotli": _brotli,
  "calc": _calc,
  "canvas-blending": _canvasBlending,
  "canvas-text": _canvasText,
  "canvas": _canvas,
  "ch-unit": _chUnit,
  "chacha20-poly1305": _chacha20Poly,
  "channel-messaging": _channelMessaging,
  "childnode-remove": _childnodeRemove,
  "classlist": _classlist,
  "client-hints-dpr-width-viewport": _clientHintsDprWidthViewport,
  "clipboard": _clipboard,
  "colr-v1": _colrV,
  "colr": _colr,
  "comparedocumentposition": _comparedocumentposition,
  "console-basic": _consoleBasic,
  "console-time": _consoleTime,
  "const": _const,
  "constraint-validation": _constraintValidation,
  "contenteditable": _contenteditable,
  "contentsecuritypolicy": _contentsecuritypolicy,
  "contentsecuritypolicy2": _contentsecuritypolicy2,
  "cookie-store-api": _cookieStoreApi,
  "cors": _cors,
  "createimagebitmap": _createimagebitmap,
  "credential-management": _credentialManagement,
  "cryptography": _cryptography,
  "css-all": _cssAll,
  "css-animation": _cssAnimation,
  "css-any-link": _cssAnyLink,
  "css-appearance": _cssAppearance,
  "css-at-counter-style": _cssAtCounterStyle,
  "css-autofill": _cssAutofill,
  "css-backdrop-filter": _cssBackdropFilter,
  "css-background-offsets": _cssBackgroundOffsets,
  "css-backgroundblendmode": _cssBackgroundblendmode,
  "css-boxdecorationbreak": _cssBoxdecorationbreak,
  "css-boxshadow": _cssBoxshadow,
  "css-canvas": _cssCanvas,
  "css-caret-color": _cssCaretColor,
  "css-cascade-layers": _cssCascadeLayers,
  "css-case-insensitive": _cssCaseInsensitive,
  "css-clip-path": _cssClipPath,
  "css-color-adjust": _cssColorAdjust,
  "css-color-function": _cssColorFunction,
  "css-conic-gradients": _cssConicGradients,
  "css-container-queries": _cssContainerQueries,
  "css-container-query-units": _cssContainerQueryUnits,
  "css-containment": _cssContainment,
  "css-content-visibility": _cssContentVisibility,
  "css-counters": _cssCounters,
  "css-crisp-edges": _cssCrispEdges,
  "css-cross-fade": _cssCrossFade,
  "css-default-pseudo": _cssDefaultPseudo,
  "css-descendant-gtgt": _cssDescendantGtgt,
  "css-deviceadaptation": _cssDeviceadaptation,
  "css-dir-pseudo": _cssDirPseudo,
  "css-display-contents": _cssDisplayContents,
  "css-element-function": _cssElementFunction,
  "css-env-function": _cssEnvFunction,
  "css-exclusions": _cssExclusions,
  "css-featurequeries": _cssFeaturequeries,
  "css-file-selector-button": _cssFileSelectorButton,
  "css-filter-function": _cssFilterFunction,
  "css-filters": _cssFilters,
  "css-first-letter": _cssFirstLetter,
  "css-first-line": _cssFirstLine,
  "css-fixed": _cssFixed,
  "css-focus-visible": _cssFocusVisible,
  "css-focus-within": _cssFocusWithin,
  "css-font-palette": _cssFontPalette,
  "css-font-rendering-controls": _cssFontRenderingControls,
  "css-font-stretch": _cssFontStretch,
  "css-gencontent": _cssGencontent,
  "css-gradients": _cssGradients,
  "css-grid-animation": _cssGridAnimation,
  "css-grid": _cssGrid,
  "css-hanging-punctuation": _cssHangingPunctuation,
  "css-has": _cssHas,
  "css-hyphens": _cssHyphens,
  "css-image-orientation": _cssImageOrientation,
  "css-image-set": _cssImageSet,
  "css-in-out-of-range": _cssInOutOfRange,
  "css-indeterminate-pseudo": _cssIndeterminatePseudo,
  "css-initial-letter": _cssInitialLetter,
  "css-initial-value": _cssInitialValue,
  "css-lch-lab": _cssLchLab,
  "css-letter-spacing": _cssLetterSpacing,
  "css-line-clamp": _cssLineClamp,
  "css-logical-props": _cssLogicalProps,
  "css-marker-pseudo": _cssMarkerPseudo,
  "css-masks": _cssMasks,
  "css-matches-pseudo": _cssMatchesPseudo,
  "css-math-functions": _cssMathFunctions,
  "css-media-interaction": _cssMediaInteraction,
  "css-media-range-syntax": _cssMediaRangeSyntax,
  "css-media-resolution": _cssMediaResolution,
  "css-media-scripting": _cssMediaScripting,
  "css-mediaqueries": _cssMediaqueries,
  "css-mixblendmode": _cssMixblendmode,
  "css-motion-paths": _cssMotionPaths,
  "css-namespaces": _cssNamespaces,
  "css-nesting": _cssNesting,
  "css-not-sel-list": _cssNotSelList,
  "css-nth-child-of": _cssNthChildOf,
  "css-opacity": _cssOpacity,
  "css-optional-pseudo": _cssOptionalPseudo,
  "css-overflow-anchor": _cssOverflowAnchor,
  "css-overflow-overlay": _cssOverflowOverlay,
  "css-overflow": _cssOverflow,
  "css-overscroll-behavior": _cssOverscrollBehavior,
  "css-page-break": _cssPageBreak,
  "css-paged-media": _cssPagedMedia,
  "css-paint-api": _cssPaintApi,
  "css-placeholder-shown": _cssPlaceholderShown,
  "css-placeholder": _cssPlaceholder,
  "css-print-color-adjust": _cssPrintColorAdjust,
  "css-read-only-write": _cssReadOnlyWrite,
  "css-rebeccapurple": _cssRebeccapurple,
  "css-reflections": _cssReflections,
  "css-regions": _cssRegions,
  "css-repeating-gradients": _cssRepeatingGradients,
  "css-resize": _cssResize,
  "css-revert-value": _cssRevertValue,
  "css-rrggbbaa": _cssRrggbbaa,
  "css-scroll-behavior": _cssScrollBehavior,
  "css-scroll-timeline": _cssScrollTimeline,
  "css-scrollbar": _cssScrollbar,
  "css-sel2": _cssSel,
  "css-sel3": _cssSel2,
  "css-selection": _cssSelection,
  "css-shapes": _cssShapes,
  "css-snappoints": _cssSnappoints,
  "css-sticky": _cssSticky,
  "css-subgrid": _cssSubgrid,
  "css-supports-api": _cssSupportsApi,
  "css-table": _cssTable,
  "css-text-align-last": _cssTextAlignLast,
  "css-text-indent": _cssTextIndent,
  "css-text-justify": _cssTextJustify,
  "css-text-orientation": _cssTextOrientation,
  "css-text-spacing": _cssTextSpacing,
  "css-textshadow": _cssTextshadow,
  "css-touch-action": _cssTouchAction,
  "css-transitions": _cssTransitions,
  "css-unicode-bidi": _cssUnicodeBidi,
  "css-unset-value": _cssUnsetValue,
  "css-variables": _cssVariables,
  "css-when-else": _cssWhenElse,
  "css-widows-orphans": _cssWidowsOrphans,
  "css-width-stretch": _cssWidthStretch,
  "css-writing-mode": _cssWritingMode,
  "css-zoom": _cssZoom,
  "css3-attr": _css3Attr,
  "css3-boxsizing": _css3Boxsizing,
  "css3-colors": _css3Colors,
  "css3-cursors-grab": _css3CursorsGrab,
  "css3-cursors-newer": _css3CursorsNewer,
  "css3-cursors": _css3Cursors,
  "css3-tabsize": _css3Tabsize,
  "currentcolor": _currentcolor,
  "custom-elements": _customElements,
  "custom-elementsv1": _customElementsv,
  "customevent": _customevent,
  "datalist": _datalist,
  "dataset": _dataset,
  "datauri": _datauri,
  "date-tolocaledatestring": _dateTolocaledatestring,
  "declarative-shadow-dom": _declarativeShadowDom,
  "decorators": _decorators,
  "details": _details,
  "deviceorientation": _deviceorientation,
  "devicepixelratio": _devicepixelratio,
  "dialog": _dialog,
  "dispatchevent": _dispatchevent,
  "dnssec": _dnssec,
  "do-not-track": _doNotTrack,
  "document-currentscript": _documentCurrentscript,
  "document-evaluate-xpath": _documentEvaluateXpath,
  "document-execcommand": _documentExeccommand,
  "document-policy": _documentPolicy,
  "document-scrollingelement": _documentScrollingelement,
  "documenthead": _documenthead,
  "dom-manip-convenience": _domManipConvenience,
  "dom-range": _domRange,
  "domcontentloaded": _domcontentloaded,
  "dommatrix": _dommatrix,
  "download": _download,
  "dragndrop": _dragndrop,
  "element-closest": _elementClosest,
  "element-from-point": _elementFromPoint,
  "element-scroll-methods": _elementScrollMethods,
  "eme": _eme,
  "eot": _eot,
  "es5": _es,
  "es6-class": _es6Class,
  "es6-generators": _es6Generators,
  "es6-module-dynamic-import": _es6ModuleDynamicImport,
  "es6-module": _es6Module,
  "es6-number": _es6Number,
  "es6-string-includes": _es6StringIncludes,
  "es6": _es2,
  "eventsource": _eventsource,
  "extended-system-fonts": _extendedSystemFonts,
  "feature-policy": _featurePolicy,
  "fetch": _fetch,
  "fieldset-disabled": _fieldsetDisabled,
  "fileapi": _fileapi,
  "filereader": _filereader,
  "filereadersync": _filereadersync,
  "filesystem": _filesystem,
  "flac": _flac,
  "flexbox-gap": _flexboxGap,
  "flexbox": _flexbox,
  "flow-root": _flowRoot,
  "focusin-focusout-events": _focusinFocusoutEvents,
  "font-family-system-ui": _fontFamilySystemUi,
  "font-feature": _fontFeature,
  "font-kerning": _fontKerning,
  "font-loading": _fontLoading,
  "font-size-adjust": _fontSizeAdjust,
  "font-smooth": _fontSmooth,
  "font-unicode-range": _fontUnicodeRange,
  "font-variant-alternates": _fontVariantAlternates,
  "font-variant-numeric": _fontVariantNumeric,
  "fontface": _fontface,
  "form-attribute": _formAttribute,
  "form-submit-attributes": _formSubmitAttributes,
  "form-validation": _formValidation,
  "forms": _forms,
  "fullscreen": _fullscreen,
  "gamepad": _gamepad,
  "geolocation": _geolocation,
  "getboundingclientrect": _getboundingclientrect,
  "getcomputedstyle": _getcomputedstyle,
  "getelementsbyclassname": _getelementsbyclassname,
  "getrandomvalues": _getrandomvalues,
  "gyroscope": _gyroscope,
  "hardwareconcurrency": _hardwareconcurrency,
  "hashchange": _hashchange,
  "heif": _heif,
  "hevc": _hevc,
  "hidden": _hidden,
  "high-resolution-time": _highResolutionTime,
  "history": _history,
  "html-media-capture": _htmlMediaCapture,
  "html5semantic": _html5semantic,
  "http-live-streaming": _httpLiveStreaming,
  "http2": _http,
  "http3": _http2,
  "iframe-sandbox": _iframeSandbox,
  "iframe-seamless": _iframeSeamless,
  "iframe-srcdoc": _iframeSrcdoc,
  "imagecapture": _imagecapture,
  "ime": _ime,
  "img-naturalwidth-naturalheight": _imgNaturalwidthNaturalheight,
  "import-maps": _importMaps,
  "imports": _imports,
  "indeterminate-checkbox": _indeterminateCheckbox,
  "indexeddb": _indexeddb,
  "indexeddb2": _indexeddb2,
  "inline-block": _inlineBlock,
  "innertext": _innertext,
  "input-autocomplete-onoff": _inputAutocompleteOnoff,
  "input-color": _inputColor,
  "input-datetime": _inputDatetime,
  "input-email-tel-url": _inputEmailTelUrl,
  "input-event": _inputEvent,
  "input-file-accept": _inputFileAccept,
  "input-file-directory": _inputFileDirectory,
  "input-file-multiple": _inputFileMultiple,
  "input-inputmode": _inputInputmode,
  "input-minlength": _inputMinlength,
  "input-number": _inputNumber,
  "input-pattern": _inputPattern,
  "input-placeholder": _inputPlaceholder,
  "input-range": _inputRange,
  "input-search": _inputSearch,
  "input-selection": _inputSelection,
  "insert-adjacent": _insertAdjacent,
  "insertadjacenthtml": _insertadjacenthtml,
  "internationalization": _internationalization,
  "intersectionobserver-v2": _intersectionobserverV,
  "intersectionobserver": _intersectionobserver,
  "intl-pluralrules": _intlPluralrules,
  "intrinsic-width": _intrinsicWidth,
  "jpeg2000": _jpeg,
  "jpegxl": _jpegxl,
  "jpegxr": _jpegxr,
  "js-regexp-lookbehind": _jsRegexpLookbehind,
  "json": _json,
  "justify-content-space-evenly": _justifyContentSpaceEvenly,
  "kerning-pairs-ligatures": _kerningPairsLigatures,
  "keyboardevent-charcode": _keyboardeventCharcode,
  "keyboardevent-code": _keyboardeventCode,
  "keyboardevent-getmodifierstate": _keyboardeventGetmodifierstate,
  "keyboardevent-key": _keyboardeventKey,
  "keyboardevent-location": _keyboardeventLocation,
  "keyboardevent-which": _keyboardeventWhich,
  "lazyload": _lazyload,
  "let": _let,
  "link-icon-png": _linkIconPng,
  "link-icon-svg": _linkIconSvg,
  "link-rel-dns-prefetch": _linkRelDnsPrefetch,
  "link-rel-modulepreload": _linkRelModulepreload,
  "link-rel-preconnect": _linkRelPreconnect,
  "link-rel-prefetch": _linkRelPrefetch,
  "link-rel-preload": _linkRelPreload,
  "link-rel-prerender": _linkRelPrerender,
  "loading-lazy-attr": _loadingLazyAttr,
  "localecompare": _localecompare,
  "magnetometer": _magnetometer,
  "matchesselector": _matchesselector,
  "matchmedia": _matchmedia,
  "mathml": _mathml,
  "maxlength": _maxlength,
  "mdn-css-unicode-bidi-isolate-override": _mdnCssUnicodeBidiIsolateOverride,
  "mdn-css-unicode-bidi-isolate": _mdnCssUnicodeBidiIsolate,
  "mdn-css-unicode-bidi-plaintext": _mdnCssUnicodeBidiPlaintext,
  "mdn-text-decoration-color": _mdnTextDecorationColor,
  "mdn-text-decoration-line": _mdnTextDecorationLine,
  "mdn-text-decoration-shorthand": _mdnTextDecorationShorthand,
  "mdn-text-decoration-style": _mdnTextDecorationStyle,
  "media-fragments": _mediaFragments,
  "mediacapture-fromelement": _mediacaptureFromelement,
  "mediarecorder": _mediarecorder,
  "mediasource": _mediasource,
  "menu": _menu,
  "meta-theme-color": _metaThemeColor,
  "meter": _meter,
  "midi": _midi,
  "minmaxwh": _minmaxwh,
  "mp3": _mp,
  "mpeg-dash": _mpegDash,
  "mpeg4": _mpeg,
  "multibackgrounds": _multibackgrounds,
  "multicolumn": _multicolumn,
  "mutation-events": _mutationEvents,
  "mutationobserver": _mutationobserver,
  "namevalue-storage": _namevalueStorage,
  "native-filesystem-api": _nativeFilesystemApi,
  "nav-timing": _navTiming,
  "netinfo": _netinfo,
  "notifications": _notifications,
  "object-entries": _objectEntries,
  "object-fit": _objectFit,
  "object-observe": _objectObserve,
  "object-values": _objectValues,
  "objectrtc": _objectrtc,
  "offline-apps": _offlineApps,
  "offscreencanvas": _offscreencanvas,
  "ogg-vorbis": _oggVorbis,
  "ogv": _ogv,
  "ol-reversed": _olReversed,
  "once-event-listener": _onceEventListener,
  "online-status": _onlineStatus,
  "opus": _opus,
  "orientation-sensor": _orientationSensor,
  "outline": _outline,
  "pad-start-end": _padStartEnd,
  "page-transition-events": _pageTransitionEvents,
  "pagevisibility": _pagevisibility,
  "passive-event-listener": _passiveEventListener,
  "passwordrules": _passwordrules,
  "path2d": _path2d,
  "payment-request": _paymentRequest,
  "pdf-viewer": _pdfViewer,
  "permissions-api": _permissionsApi,
  "permissions-policy": _permissionsPolicy,
  "picture-in-picture": _pictureInPicture,
  "picture": _picture,
  "ping": _ping,
  "png-alpha": _pngAlpha,
  "pointer-events": _pointerEvents,
  "pointer": _pointer,
  "pointerlock": _pointerlock,
  "portals": _portals,
  "prefers-color-scheme": _prefersColorScheme,
  "prefers-reduced-motion": _prefersReducedMotion,
  "progress": _progress,
  "promise-finally": _promiseFinally,
  "promises": _promises,
  "proximity": _proximity,
  "proxy": _proxy,
  "publickeypinning": _publickeypinning,
  "push-api": _pushApi,
  "queryselector": _queryselector,
  "readonly-attr": _readonlyAttr,
  "referrer-policy": _referrerPolicy,
  "registerprotocolhandler": _registerprotocolhandler,
  "rel-noopener": _relNoopener,
  "rel-noreferrer": _relNoreferrer,
  "rellist": _rellist,
  "rem": _rem,
  "requestanimationframe": _requestanimationframe,
  "requestidlecallback": _requestidlecallback,
  "resizeobserver": _resizeobserver,
  "resource-timing": _resourceTiming,
  "rest-parameters": _restParameters,
  "rtcpeerconnection": _rtcpeerconnection,
  "ruby": _ruby,
  "run-in": _runIn,
  "same-site-cookie-attribute": _sameSiteCookieAttribute,
  "screen-orientation": _screenOrientation,
  "script-async": _scriptAsync,
  "script-defer": _scriptDefer,
  "scrollintoview": _scrollintoview,
  "scrollintoviewifneeded": _scrollintoviewifneeded,
  "sdch": _sdch,
  "selection-api": _selectionApi,
  "server-timing": _serverTiming,
  "serviceworkers": _serviceworkers,
  "setimmediate": _setimmediate,
  "shadowdom": _shadowdom,
  "shadowdomv1": _shadowdomv,
  "sharedarraybuffer": _sharedarraybuffer,
  "sharedworkers": _sharedworkers,
  "sni": _sni,
  "spdy": _spdy,
  "speech-recognition": _speechRecognition,
  "speech-synthesis": _speechSynthesis,
  "spellcheck-attribute": _spellcheckAttribute,
  "sql-storage": _sqlStorage,
  "srcset": _srcset,
  "stream": _stream,
  "streams": _streams,
  "stricttransportsecurity": _stricttransportsecurity,
  "style-scoped": _styleScoped,
  "subresource-bundling": _subresourceBundling,
  "subresource-integrity": _subresourceIntegrity,
  "svg-css": _svgCss,
  "svg-filters": _svgFilters,
  "svg-fonts": _svgFonts,
  "svg-fragment": _svgFragment,
  "svg-html": _svgHtml,
  "svg-html5": _svgHtml2,
  "svg-img": _svgImg,
  "svg-smil": _svgSmil,
  "svg": _svg,
  "sxg": _sxg,
  "tabindex-attr": _tabindexAttr,
  "template-literals": _templateLiterals,
  "template": _template,
  "temporal": _temporal,
  "testfeat": _testfeat,
  "text-decoration": _textDecoration,
  "text-emphasis": _textEmphasis,
  "text-overflow": _textOverflow,
  "text-size-adjust": _textSizeAdjust,
  "text-stroke": _textStroke,
  "textcontent": _textcontent,
  "textencoder": _textencoder,
  "tls1-1": _tls,
  "tls1-2": _tls2,
  "tls1-3": _tls3,
  "touch": _touch,
  "transforms2d": _transforms2d,
  "transforms3d": _transforms3d,
  "trusted-types": _trustedTypes,
  "ttf": _ttf,
  "typedarrays": _typedarrays,
  "u2f": _u2f,
  "unhandledrejection": _unhandledrejection,
  "upgradeinsecurerequests": _upgradeinsecurerequests,
  "url-scroll-to-text-fragment": _urlScrollToTextFragment,
  "url": _url,
  "urlsearchparams": _urlsearchparams,
  "use-strict": _useStrict,
  "user-select-none": _userSelectNone,
  "user-timing": _userTiming,
  "variable-fonts": _variableFonts,
  "vector-effect": _vectorEffect,
  "vibration": _vibration,
  "video": _video,
  "videotracks": _videotracks,
  "viewport-unit-variants": _viewportUnitVariants,
  "viewport-units": _viewportUnits,
  "wai-aria": _waiAria,
  "wake-lock": _wakeLock,
  "wasm": _wasm,
  "wav": _wav,
  "wbr-element": _wbrElement,
  "web-animation": _webAnimation,
  "web-app-manifest": _webAppManifest,
  "web-bluetooth": _webBluetooth,
  "web-serial": _webSerial,
  "web-share": _webShare,
  "webauthn": _webauthn,
  "webcodecs": _webcodecs,
  "webgl": _webgl,
  "webgl2": _webgl2,
  "webgpu": _webgpu,
  "webhid": _webhid,
  "webkit-user-drag": _webkitUserDrag,
  "webm": _webm,
  "webnfc": _webnfc,
  "webp": _webp,
  "websockets": _websockets,
  "webtransport": _webtransport,
  "webusb": _webusb,
  "webvr": _webvr,
  "webvtt": _webvtt,
  "webworkers": _webworkers,
  "webxr": _webxr,
  "will-change": _willChange,
  "woff": _woff,
  "woff2": _woff2,
  "word-break": _wordBreak,
  "wordwrap": _wordwrap,
  "x-doc-messaging": _xDocMessaging,
  "x-frame-options": _xFrameOptions,
  "xhr2": _xhr,
  "xhtml": _xhtml,
  "xhtmlsmil": _xhtmlsmil,
  "xml-serializer": _xmlSerializer
};
export default exports;