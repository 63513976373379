var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 6B"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "2": "C K L G M N O"
    },
    C: {
      "1": "PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "0 1 2 3 4 5 6 7 8 9 7B qB I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB 8B 9B"
    },
    D: {
      "1": "MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB"
    },
    E: {
      "1": "B C K L G xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I r J D E F A CC wB DC EC FC GC"
    },
    F: {
      "1": "9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "0 1 2 3 4 5 6 7 8 F B C G M N O s t u v w x y z LC MC NC OC nB 4B PC oB"
    },
    G: {
      "1": "YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "E wB QC 5B RC SC TC UC VC WC XC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "1": "H",
      "2": "qB I lC mC nC oC 5B pC qC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "d",
      "2": "A B C nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C",
      "2": "I"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "2": "6C"
    }
  },
  B: 1,
  C: "rel=noopener"
};
export default exports;