var exports = {};
exports = {
  A: {
    A: {
      "132": "J D E F A B 6B"
    },
    B: {
      "2": "C K L G M N O",
      "292": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 7B qB I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB 8B 9B",
      "3074": "YB",
      "4100": "ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB"
    },
    D: {
      "292": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC"
    },
    E: {
      "16": "I r CC wB",
      "292": "J D E F A B C K L G DC EC FC GC xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC"
    },
    F: {
      "2": "F B C LC MC NC OC nB 4B PC oB",
      "292": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b"
    },
    G: {
      "2": "hC iC jC zB 0B 1B 2B pB 3B",
      "16": "wB QC 5B RC SC",
      "292": "TC",
      "804": "E UC VC WC XC YC ZC aC bC cC dC eC fC gC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "16": "lC mC",
      "292": "qB I H nC oC 5B pC qC"
    },
    J: {
      "292": "D A"
    },
    K: {
      "2": "A B C nB 4B oB",
      "292": "d"
    },
    L: {
      "292": "H"
    },
    M: {
      "2": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "292": "rC"
    },
    P: {
      "292": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "292": "yB"
    },
    R: {
      "292": "5C"
    },
    S: {
      "2": "6C"
    }
  },
  B: 7,
  C: "CSS scrollbar styling"
};
export default exports;