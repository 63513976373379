var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F 6B",
      "260": "A B"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "260": "C K L G M N O"
    },
    C: {
      "1": "1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB 8B",
      "260": "0 I r J D E F A B C K L G M N O s t u v w x y z 9B"
    },
    D: {
      "1": "BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "I r",
      "260": "0 1 2 3 4 5 6 7 8 9 K L G M N O s t u v w x y z AB",
      "388": "J D E F A B C"
    },
    E: {
      "1": "A B C K L G xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I r CC wB",
      "260": "J D E F EC FC GC",
      "388": "DC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "F B LC MC NC OC",
      "260": "C G M N O s t u v w x nB 4B PC oB"
    },
    G: {
      "1": "XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "wB QC 5B RC",
      "260": "E SC TC UC VC WC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "1": "H qC",
      "2": "lC mC nC",
      "260": "pC",
      "388": "qB I oC 5B"
    },
    J: {
      "260": "A",
      "388": "D"
    },
    K: {
      "1": "d",
      "2": "A B",
      "260": "C nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2": "A",
      "260": "B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 5,
  C: "File API"
};
export default exports;