var exports = {};
exports = {
  A: {
    A: {
      "1": "F A B",
      "2": "J D E 6B"
    },
    B: {
      "1": "M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "516": "C K L G"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB 8B 9B",
      "260": "I r J D E F A B C K L G M N O s t u v w"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "4": "I"
    },
    E: {
      "1": "r J D E F A B C K L G DC EC FC GC xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "CC",
      "132": "I wB"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 B C G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b LC MC NC OC nB 4B PC oB",
      "2": "F"
    },
    G: {
      "1": "E 5B RC SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "132": "wB QC"
    },
    H: {
      "260": "kC"
    },
    I: {
      "1": "qB I H oC 5B pC qC",
      "2": "lC mC nC"
    },
    J: {
      "1": "D A"
    },
    K: {
      "1": "d",
      "260": "A B C nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 4,
  C: "SVG in CSS backgrounds"
};
export default exports;