var exports = {};
exports = {
  A: {
    A: {
      "2436": "J D E F A B 6B"
    },
    B: {
      "260": "N O",
      "2436": "C K L G M",
      "8196": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "2": "7B qB I r J D E F A B C K L G M N O s t u 8B 9B",
      "772": "0 1 2 3 4 5 6 7 8 9 v w x y z AB BB CB DB",
      "4100": "EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB"
    },
    D: {
      "2": "I r J D E F A B C",
      "2564": "0 1 2 3 4 5 6 7 8 9 K L G M N O s t u v w x y z AB BB CB DB EB FB",
      "8196": "VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "10244": "GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB"
    },
    E: {
      "1": "C K L G oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "16": "CC wB",
      "2308": "A B xB nB",
      "2820": "I r J D E F DC EC FC GC"
    },
    F: {
      "2": "F B LC MC NC OC nB 4B PC",
      "16": "C",
      "516": "oB",
      "2564": "0 1 2 G M N O s t u v w x y z",
      "8196": "IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "10244": "3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB"
    },
    G: {
      "1": "bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "wB QC 5B",
      "2820": "E RC SC TC UC VC WC XC YC ZC aC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "2": "qB I lC mC nC oC 5B",
      "260": "H",
      "2308": "pC qC"
    },
    J: {
      "2": "D",
      "2308": "A"
    },
    K: {
      "2": "A B C nB 4B",
      "16": "oB",
      "8196": "d"
    },
    L: {
      "8196": "H"
    },
    M: {
      "1028": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "8196": "rC"
    },
    P: {
      "2052": "sC tC",
      "2308": "I",
      "8196": "uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "8196": "yB"
    },
    R: {
      "8196": "5C"
    },
    S: {
      "4100": "6C"
    }
  },
  B: 5,
  C: "Synchronous Clipboard API"
};
export default exports;