var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 6B"
    },
    B: {
      "1": "X Y Z a b e f g h i j k l m n o p q c H",
      "2": "C K L G M N O",
      "1220": "P Q R S T U V W"
    },
    C: {
      "1": "mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "16": "7B qB 8B 9B",
      "548": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB"
    },
    D: {
      "1": "X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "16": "I r J D E F A B C K L",
      "164": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB",
      "196": "aB bB cB",
      "1220": "dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W"
    },
    E: {
      "1": "L G HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I CC wB",
      "16": "r",
      "164": "J D E DC EC FC",
      "260": "F A B C K GC xB nB oB yB"
    },
    F: {
      "1": "jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "F B C LC MC NC OC nB 4B PC oB",
      "164": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB",
      "196": "PB QB RB",
      "1220": "SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB"
    },
    G: {
      "1": "hC iC jC zB 0B 1B 2B pB 3B",
      "16": "wB QC 5B RC SC",
      "164": "E TC UC",
      "260": "VC WC XC YC ZC aC bC cC dC eC fC gC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "1": "H",
      "16": "qB lC mC nC",
      "164": "I oC 5B pC qC"
    },
    J: {
      "16": "D",
      "164": "A"
    },
    K: {
      "1": "d",
      "2": "A B C nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "164": "rC"
    },
    P: {
      "1": "1C pB 2C 3C 4C",
      "164": "I sC tC uC vC wC xB xC yC zC 0C"
    },
    Q: {
      "1220": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "548": "6C"
    }
  },
  B: 5,
  C: ":is() CSS pseudo-class"
};
export default exports;