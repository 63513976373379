var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B 6B"
    },
    B: {
      "2": "C K L",
      "33": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "161": "G M N O"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 7B qB I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB 8B 9B",
      "161": "MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "450": "LB"
    },
    D: {
      "33": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC"
    },
    E: {
      "33": "I r J D E F A B C K L G CC wB DC EC FC GC xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC"
    },
    F: {
      "2": "F B C LC MC NC OC nB 4B PC oB",
      "33": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b"
    },
    G: {
      "33": "E QC 5B RC SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "36": "wB"
    },
    H: {
      "2": "kC"
    },
    I: {
      "2": "qB",
      "33": "I H lC mC nC oC 5B pC qC"
    },
    J: {
      "33": "D A"
    },
    K: {
      "2": "A B C nB 4B oB",
      "33": "d"
    },
    L: {
      "33": "H"
    },
    M: {
      "161": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "33": "rC"
    },
    P: {
      "33": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "33": "yB"
    },
    R: {
      "33": "5C"
    },
    S: {
      "161": "6C"
    }
  },
  B: 7,
  C: "CSS text-stroke and text-fill"
};
export default exports;