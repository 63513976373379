var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F 6B",
      "900": "A B"
    },
    B: {
      "1": "N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "388": "L G M",
      "900": "C K"
    },
    C: {
      "1": "OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB 8B 9B",
      "260": "MB NB",
      "388": "2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB",
      "900": "0 1 I r J D E F A B C K L G M N O s t u v w x y z"
    },
    D: {
      "1": "DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "16": "I r J D E F A B C K L",
      "388": "0 1 2 3 4 5 6 7 8 9 y z AB BB CB",
      "900": "G M N O s t u v w x"
    },
    E: {
      "1": "A B C K L G xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "16": "I r CC wB",
      "388": "E F FC GC",
      "900": "J D DC EC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "16": "F B LC MC NC OC nB 4B",
      "388": "G M N O s t u v w x y z",
      "900": "C PC oB"
    },
    G: {
      "1": "XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "16": "wB QC 5B",
      "388": "E TC UC VC WC",
      "900": "RC SC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "1": "H",
      "16": "qB lC mC nC",
      "388": "pC qC",
      "900": "I oC 5B"
    },
    J: {
      "16": "D",
      "388": "A"
    },
    K: {
      "1": "d",
      "16": "A B nB 4B",
      "900": "C oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "900": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "388": "6C"
    }
  },
  B: 1,
  C: "Constraint Validation API"
};
export default exports;