var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A 6B",
      "388": "B"
    },
    B: {
      "257": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "260": "C K L",
      "769": "G M N O"
    },
    C: {
      "2": "7B qB I r 8B 9B",
      "4": "0 1 2 3 4 5 6 7 8 9 J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB",
      "257": "RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB"
    },
    D: {
      "2": "I r J D E F A B C K L G M N O s t",
      "4": "0 1 2 3 4 5 6 7 8 9 u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB",
      "257": "OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC"
    },
    E: {
      "1": "A B C K L G xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I r J D CC wB DC EC",
      "4": "E F FC GC"
    },
    F: {
      "2": "F B C LC MC NC OC nB 4B PC oB",
      "4": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB",
      "257": "BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b"
    },
    G: {
      "1": "XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "wB QC 5B RC SC",
      "4": "E TC UC VC WC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "2": "qB I lC mC nC oC 5B",
      "4": "pC qC",
      "257": "H"
    },
    J: {
      "2": "D",
      "4": "A"
    },
    K: {
      "2": "A B C nB 4B oB",
      "257": "d"
    },
    L: {
      "257": "H"
    },
    M: {
      "257": "c"
    },
    N: {
      "2": "A",
      "388": "B"
    },
    O: {
      "257": "rC"
    },
    P: {
      "4": "I",
      "257": "sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "257": "yB"
    },
    R: {
      "257": "5C"
    },
    S: {
      "4": "6C"
    }
  },
  B: 6,
  C: "ECMAScript 2015 (ES6)"
};
export default exports;