var exports = {};
exports = {
  A: {
    A: {
      "16": "6B",
      "132": "J D E F A B"
    },
    B: {
      "1": "O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "132": "C K L G M N"
    },
    C: {
      "1": "TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "132": "0 1 7B qB I r J D E F A B C K L G M N O s t u v w x y z 8B 9B",
      "260": "PB QB RB SB",
      "772": "2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB"
    },
    D: {
      "1": "fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "132": "I r J D E F A B C K L G M N O s t u v w",
      "260": "BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB",
      "772": "0 1 2 3 4 5 6 7 8 9 x y z AB"
    },
    E: {
      "1": "C K L G oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "16": "I r CC wB",
      "132": "J D E F A DC EC FC GC",
      "260": "B xB nB"
    },
    F: {
      "1": "UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "16": "F B C LC MC NC OC nB 4B PC",
      "132": "oB",
      "260": "0 1 2 3 4 5 6 7 8 9 y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB",
      "772": "G M N O s t u v w x"
    },
    G: {
      "1": "YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "16": "wB QC 5B RC",
      "132": "E SC TC UC VC WC XC"
    },
    H: {
      "132": "kC"
    },
    I: {
      "1": "H",
      "16": "qB lC mC nC",
      "132": "I oC 5B",
      "772": "pC qC"
    },
    J: {
      "132": "D A"
    },
    K: {
      "1": "d",
      "16": "A B C nB 4B",
      "132": "oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "wC xB xC yC zC 0C 1C pB 2C 3C 4C",
      "260": "I sC tC uC vC"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "132": "6C"
    }
  },
  B: 6,
  C: "Date.prototype.toLocaleDateString"
};
export default exports;