var exports = {};
exports = {
  A: {
    A: {
      "8": "J D E F 6B",
      "1924": "A B"
    },
    B: {
      "1": "C K L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "8": "7B qB 8B",
      "516": "x y",
      "772": "I r J D E F A B C K L G M N O s t u v w 9B"
    },
    D: {
      "1": "1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "8": "I r J D",
      "516": "0 x y z",
      "772": "w",
      "900": "E F A B C K L G M N O s t u v"
    },
    E: {
      "1": "D E F A B C K L G FC GC xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "8": "I r CC wB",
      "900": "J DC EC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "8": "F B LC MC NC OC nB",
      "900": "C 4B PC oB"
    },
    G: {
      "1": "E TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "8": "wB QC 5B",
      "900": "RC SC"
    },
    H: {
      "900": "kC"
    },
    I: {
      "1": "H pC qC",
      "8": "lC mC nC",
      "900": "qB I oC 5B"
    },
    J: {
      "1": "A",
      "900": "D"
    },
    K: {
      "1": "d",
      "8": "A B",
      "900": "C nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "900": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 1,
  C: "classList (DOMTokenList)"
};
export default exports;