var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F 6B",
      "132": "A B"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "132": "C K L G M N O"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB 8B 9B",
      "33": "A B C K L G",
      "36": "I r J D E F"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "A",
      "8": "I r J D E F",
      "33": "w",
      "36": "B C K L G M N O s t u v"
    },
    E: {
      "1": "A B C K L G xB nB oB yB IC zB 0B 1B 2B pB 3B JC KC",
      "8": "I r J D CC wB DC EC",
      "260": "E F FC GC",
      "516": "HC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "F LC MC",
      "8": "B C NC OC nB 4B PC oB"
    },
    G: {
      "1": "XC YC ZC aC bC cC dC eC fC gC hC jC zB 0B 1B 2B pB 3B",
      "8": "wB QC 5B RC SC TC",
      "260": "E UC VC WC",
      "516": "iC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "1": "H pC qC",
      "8": "qB I lC mC nC oC 5B"
    },
    J: {
      "1": "A",
      "8": "D"
    },
    K: {
      "1": "d",
      "2": "A",
      "8": "B C nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 2,
  C: "IndexedDB"
};
export default exports;