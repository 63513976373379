var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "2": "J D E F A 6B"
    },
    B: {
      "1": "C K L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB 8B 9B"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "I r"
    },
    E: {
      "1": "J D E F A B C K L G DC EC FC GC xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I r CC wB"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 C G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b nB 4B PC oB",
      "2": "F B LC MC NC OC"
    },
    G: {
      "1": "E RC SC TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "wB QC 5B"
    },
    H: {
      "1": "kC"
    },
    I: {
      "1": "I H oC 5B pC qC",
      "2": "qB lC mC nC"
    },
    J: {
      "1": "A",
      "2": "D"
    },
    K: {
      "1": "C d nB 4B oB",
      "2": "A B"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "1": "B",
      "2": "A"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 1,
  C: "hidden attribute"
};
export default exports;