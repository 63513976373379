var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E 6B",
      "2561": "A B",
      "2692": "F"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "2561": "C K L G M N O"
    },
    C: {
      "1": "MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "16": "7B",
      "1537": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB 9B",
      "1796": "qB 8B"
    },
    D: {
      "1": "bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "16": "I r J D E F A B C K L",
      "1025": "8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB",
      "1537": "0 1 2 3 4 5 6 7 G M N O s t u v w x y z"
    },
    E: {
      "1": "L G yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "16": "I r J CC wB",
      "1025": "D E F A B C EC FC GC xB nB",
      "1537": "DC",
      "4097": "K oB"
    },
    F: {
      "1": "PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b oB",
      "16": "F B C LC MC NC OC nB 4B",
      "260": "PC",
      "1025": "0 1 2 3 4 5 6 7 8 9 v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB",
      "1537": "G M N O s t u"
    },
    G: {
      "1": "eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "16": "wB QC 5B",
      "1025": "E UC VC WC XC YC ZC aC bC",
      "1537": "RC SC TC",
      "4097": "cC dC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "16": "lC mC",
      "1025": "H qC",
      "1537": "qB I nC oC 5B pC"
    },
    J: {
      "1025": "A",
      "1537": "D"
    },
    K: {
      "1": "A B C d nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2561": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1025": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "1537": "6C"
    }
  },
  B: 1,
  C: "input event"
};
export default exports;