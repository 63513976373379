var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A 6B",
      "388": "B"
    },
    B: {
      "1": "O P Q R S T U",
      "2": "C K L G",
      "129": "M N",
      "513": "V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "1": "WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "0 1 2 3 4 5 6 7 8 9 7B qB I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB 8B 9B"
    },
    D: {
      "1": "OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P",
      "2": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB",
      "513": "Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC"
    },
    E: {
      "1": "G IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I r J D E F A B CC wB DC EC FC GC xB nB",
      "2052": "L HC",
      "3076": "C K oB yB"
    },
    F: {
      "1": "CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB",
      "2": "0 1 2 3 4 5 6 7 8 9 F B C G M N O s t u v w x y z AB BB LC MC NC OC nB 4B PC oB",
      "513": "gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b"
    },
    G: {
      "1": "dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "E wB QC 5B RC SC TC UC VC WC XC YC ZC aC",
      "2052": "bC cC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "1": "H",
      "2": "qB I lC mC nC oC 5B pC qC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "2": "A B C nB 4B oB",
      "513": "d"
    },
    L: {
      "513": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "rC"
    },
    P: {
      "1": "sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C",
      "2": "I"
    },
    Q: {
      "16": "yB"
    },
    R: {
      "513": "5C"
    },
    S: {
      "2": "6C"
    }
  },
  B: 6,
  C: "'SameSite' cookie attribute"
};
export default exports;